import React from "react"

import { TOOLS } from "../../utils/constant"
import Layout from "../../layouts"

import "./index.css"

class ToolList extends React.Component {
  render() {
    return (
      <Layout isSpacerDisplay={true} title="小工具">
        <div className="mdnice-tool-container">
          <div className="mdnice-tool-list">
            {TOOLS.map((item, index) => (
              <a key={index} href={item.link}>
                <div className="mdnice-tool-block">
                  <img alt="" src={item.banner} />
                  <div className="mdnice-tool-word">
                    <p className="mdnice-tool-title">{item.title}</p>
                    <p className="mdnice-tool-desc">{item.desc}</p>
                  </div>
                </div>
              </a>
            ))}
            <div style={{ width: 250, margin: "0em 1em" }} />
            <div style={{ width: 250, margin: "0em 1em" }} />
            <div style={{ width: 250, margin: "0em 1em" }} />
          </div>
        </div>
      </Layout>
    )
  }
}

export default ToolList
